const projects = [
  {
    title: "Records",
    app: "",
    code: "https://github.com/brooklynrauckman/records",
    preview: "/records-shot.png",
    thumbnail: "/records.mp4",
    caption:
      "Use this native app to keep your collection of records up-to-date. Search or sort your collection to find specific records.",
    tools: [
      "/html.svg",
      "/css.svg",
      "/javascript.svg",
      "/react.svg",
      "/firebase.svg",
      "/redux.svg",
      "/git.svg",
    ],
    media: "video",
    poster: "/records.png",
    id: "records",
  },
  {
    title: "Sustainable Swaps",
    app: "https://confident-euler-0c7809.netlify.com/",
    code: "https://github.com/brooklynrauckman/sustainable-swaps",
    preview: "/swaps.png",
    thumbnail: "/swaps-shot.png",
    caption:
      "Search for sustainable swaps. See how much you could save. Add swaps to your bag and share the link with your friends.",
    tools: [
      "/html.svg",
      "/css.svg",
      "/javascript.svg",
      "/react.svg",
      "/netlify.svg",
      "/git.svg",
    ],
    media: "image",
    id: "swaps",
  },
  {
    title: "I Wonder",
    app: "https://competent-rosalind-8f9dcf.netlify.com/",
    code: "https://github.com/brooklynrauckman/wonder",
    preview: "/wonder.png",
    thumbnail: "/wonder-shot.png",
    caption:
      "Explore mind-blowing comparisons involving objects in outer space. Add your name to the guest galaxy, and see who else has joined.",
    tools: [
      "/html.svg",
      "/css.svg",
      "/javascript.svg",
      "/react.svg",
      "/node.svg",
      "/postgresql.svg",
      "/netlify.svg",
      "/git.svg",
    ],
    media: "image",
    id: "space",
  },
  {
    title: "Challenge By Choice",
    app: "https://reverent-mcclintock-1d3ef5.netlify.com/",
    code: "https://github.com/brooklynrauckman/challenge-by-choice",
    preview: "/challenge.png",
    thumbnail: "/challenge-shot.png",
    caption:
      "This is a flexible goal tracking app. Sign-in with google, add custom challenges, and mark challenges as achieved.",
    tools: [
      "/html.svg",
      "/css.svg",
      "/javascript.svg",
      "/react.svg",
      "/firebase.svg",
      "/netlify.svg",
      "/git.svg",
    ],
    media: "image",
    id: "challenge",
  },
  {
    title: "Freedom Age",
    app: "https://friendly-lewin-7b02bf.netlify.com/",
    code: "https://github.com/brooklynrauckman/freedom",
    preview: "/freedom.png",
    thumbnail: "/freedom-shot.png",
    caption:
      "See if you are on track to retire early. Experiment with different scenarios to see what it would take.",
    tools: [
      "/html.svg",
      "/css.svg",
      "/javascript.svg",
      "/react.svg",
      "/netlify.svg",
      "/git.svg",
    ],
    media: "image",
    id: "freedom",
  },
  {
    title: "ATSA Practice",
    app: "https://fervent-swanson-6d50df.netlify.app",
    code: "https://github.com/brooklynrauckman/atc-app",
    preview: "/atc.png",
    thumbnail: "/atc-shot.png",
    caption:
      "These activities are designed as preparation for the ATSA. However, they are also just a fun way to test your memorization, focus, mental math, and speed.",
    tools: [
      "/html.svg",
      "/css.svg",
      "/javascript.svg",
      "/react.svg",
      "/netlify.svg",
      "/git.svg",
    ],
    media: "image",
    id: "atc",
  },
];

const skills = [
  {
    title: "JavaScript",
    link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
    icon: "/javascript.svg",
    id: "JavaScript",
  },
  {
    title: "HTML",
    link: "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5",
    icon: "/html.svg",
    id: "HTML",
  },
  {
    title: "CSS",
    link: "https://developer.mozilla.org/en-US/docs/Web/CSS",
    icon: "/css.svg",
    id: "CSS",
  },
  {
    title: "React + Native",
    link: "https://reactjs.org/",
    icon: "/react.svg",
    id: "React",
  },
  {
    title: "Netlify",
    link: "https://www.netlify.com/",
    icon: "/netlify.svg",
    id: "Netlify",
  },
  {
    title: "Node.js",
    link: "https://nodejs.org/en/",
    icon: "/node.svg",
    id: "Node",
  },
  {
    title: "Postgresql",
    link: "https://www.postgresql.org/",
    icon: "/postgresql.svg",
    id: "Postgresql",
  },
  {
    title: "Firebase",
    link: "https://firebase.google.com/",
    icon: "/firebase.svg",
    id: "Firebase",
  },
  {
    title: "Redux",
    link: "https://redux.js.org/",
    icon: "/redux.svg",
    id: "Redux",
  },
  {
    title: "Git",
    link: "https://git-scm.com/",
    icon: "/git.svg",
    id: "Git",
  },
];

export default {
  projects: projects,
  skills: skills,
};
